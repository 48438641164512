<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.transactionScores") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-date-picker
            size="mini"
            v-model="filterForm.start_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.start_date')"
          ></el-date-picker>
          <el-date-picker
            size="mini"
            v-model="filterForm.end_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.end_date')"
          ></el-date-picker>
           <!-- <span class="align-items-center align-self-center pr-2">
            <b>
              <el-tag>
                {{ $t("message.total_amount") }}:  {{ total.toFixed(2) }}
              </el-tag>
              <el-tag >{{ $t("message.cach") }}:
              </el-tag>
              <el-tag
                >{{ $t("message.terminal") }}:
              </el-tag>
              <el-tag>
                 Click | Payme:  
              </el-tag>
            </b>
          </span> -->
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
        "
      ></div>
    </div>

    <div
      style="text-align: center"
      class="col-12 align-items-center align-self-center text-center pr-0 mb-2"
      v-for="(index, item_score) in score"
      :key="item_score"
    >
      <el-divider content-position="left">
        {{ item_score }}
      </el-divider>

      <div
        v-for="(index_item_score, item_item_score) in index"
        :key="item_item_score"
        class="my-3"
      >
        {{ item_item_score == "payment" ? $t("message.payment") : "" }}
        {{ item_item_score == "costs" ? $t("message.expences") : "" }}
        {{ item_item_score == "refund" ? $t("message.refund") : "" }}
          <span class="align-items-center align-self-center pr-2">
            <b>
              <el-tag v-if="item_item_score == 'refund'" type="danger"
                >{{ $t("message.total_amount") }}:
                {{ index_item_score.total | formatMoney }}
              </el-tag>
              <el-tag v-if="item_item_score == 'costs'" type="warning"
                >{{ $t("message.total_amount") }}:
                {{ index_item_score.total | formatMoney }}
              </el-tag>
              <el-tag v-if="item_item_score == 'payment'"
                >{{ $t("message.total_amount") }}:
                {{ index_item_score.total | formatMoney }}
              </el-tag>
            </b>
          </span>
          <span
            v-for="item in paymentTypes"
            :key="item.id"
            class="align-items-center align-self-center pr-2"
          >
            <b>
              <el-tag v-if="item_item_score == 'refund'" type="danger"
                >{{ item.name | uppercaseFirst }}:
                {{ index_item_score[item.id] | formatMoney }}
              </el-tag>
              <el-tag v-if="item_item_score == 'costs'" type="warning"
                >{{ item.name | uppercaseFirst }}:
                {{ index_item_score[item.id] | formatMoney }}
              </el-tag>
              <el-tag v-if="item_item_score == 'payment'"
                >{{ item.name | uppercaseFirst }}:
                {{ index_item_score[item.id] | formatMoney }}
              </el-tag>
            </b>
          </span>
        </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from "vuex";
export default {
  name: "PatientServiceList",

  data() {
    return {
      loadTable: false,
      tabAddPayment: false,
      drawerUpdate: false,
      drawerFind: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      filterForm: {},
      today_transactions: 0,
      payment: {},
      costs: {},
      total: 0,
      cash: 0,
      terminal: 0,
      click: 0
    };
  },
  computed: {
    ...mapGetters({
      list: "transaction/reports",
      score: "transaction/score",
      filter: "transaction/filter",
      paymentTypes: "paymentTypes/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
  },
  async mounted() {
    this.updateList().then(() => {
      for (let key in this.score) {
        if (key === 'Общее') {
          this.total = _.round((parseFloat(this.score[key]['payment']['total']) - this.score[key]['costs']['total']), 2);
          this.payment['cash'] = parseFloat(this.score[key]['payment'][1]);
          this.payment['terminal'] = parseFloat(this.score[key]['payment'][2]);
          this.payment['click'] = parseFloat(this.score[key]['payment'][3]);
          this.payment['total'] = parseFloat(this.score[key]['payment']['total']);

          this.costs['cash'] = this.score[key]['costs'][1];
          this.costs['terminal'] = this.score[key]['costs'][2];
          this.costs['click'] = this.score[key]['costs'][3];
          this.costs['total'] = this.score[key]['costs']['total'];
          console.log('payment', this.payment);
          console.log('costs', this.costs);
        }
      }
      
    });

    if (this.paymentTypes && this.paymentTypes.length === 0)
      await this.paymentType();
  },
  watch: {
    filterForm: {
      handler: async function (newVal, oldVal) {
        await this.updateList(newVal);
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      updateFilter: "transaction/updateFilter",
      paymentType: "paymentTypes/index",
      updateList: "transaction/scoreReport",
      refreshData: "transaction/refreshData",
    }),
    async fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.today_transactions = res.data.today_transactions;
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },

    refresh() {
      this.updateList()
        .then((res) => {
          //   this.filterForm = JSON.parse( JSON.stringify( this.filter ))
        })
        .catch((err) => {});
    },

    emptyModel() {
      this.empty();
    },
  },
};
</script>


